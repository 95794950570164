import ContentstackLivePreview from '@contentstack/live-preview-utils'
import csStyleSheetUrl from '@contentstack/live-preview-utils/dist/main.css?url'
import { Button } from '@datasnipper/ui/button'
import uiStyleSheetUrl from '@datasnipper/ui/styles.css?url'
import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { useEffect } from 'react'
import hotjar from 'react-hotjar'

import { CsFooter } from './contentstack/footer.tsx'
import { CsNavigation } from './contentstack/navigation.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getFooter, getNavigation, isPreview } from './utils/api.ts'
import { getEnv } from './utils/env.server.ts'
import * as gtag from './utils/gtags.client'
import { getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'

export const links: LinksFunction = () => [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicons/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png',
  },
  { rel: 'manifest', href: '/site.webmanifest' },
  { rel: 'icon', href: '/favicon.ico' },
  { rel: 'stylesheet', href: uiStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  { rel: 'stylesheet', href: csStyleSheetUrl },
]

export type RootLoaderType = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url)
  ContentstackLivePreview.setConfigFromParams(searchParams)

  const preview = isPreview()
  const navigation = await getNavigation({ preview })
  const footer = await getFooter({ preview })

  return json({
    navigation,
    footer,
    ENV: getEnv(),
    requestInfo: {
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
    },
  })
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')
  const nonce = useNonce()
  const location = useLocation()

  useEffect(() => {
    if (data?.ENV.GA_TRACKING_ID) {
      gtag.pageview(location.pathname, data?.ENV.GA_TRACKING_ID)
    }
  }, [location, data?.ENV.GA_TRACKING_ID])

  ContentstackLivePreview.init({
    enable: true,
    clientUrlParams: {
      host: 'eu-app.contentstack.com',
    },
    stackDetails: {
      apiKey: data?.ENV.CONTENTSTACK_API_KEY,
      environment: data?.ENV.CONTENTSTACK_ENVIRONMENT,
    },
  })?.catch(err => console.error(err))

  useEffect(() => {
    if (data?.ENV.HOTJAR_SITE_ID) {
      hotjar.hotjar.initialize({
        id: Number(data.ENV.HOTJAR_SITE_ID),
        sv: Number(data.ENV.HOTJAR_VERSION ?? 6),
      })
    }
  }, [data?.ENV.HOTJAR_SITE_ID, data?.ENV.HOTJAR_VERSION])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />

        {data?.ENV.GA_TRACKING_ID ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${data?.ENV.GA_TRACKING_ID}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
  
                  gtag('config', '${data?.ENV.GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
                `,
              }}
            />
          </>
        ) : null}
      </head>
      <body className="antialiased">
        <CsNavigation entry={data?.navigation} />
        {children}
        <CsFooter entry={data?.footer} />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

export default function App() {
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <main className="flex h-[70svh] w-screen items-center justify-center">
        <div className="text-center">
          <h1 className="mb-4 text-3xl font-bold text-body">
            Oops. This page was not found.
          </h1>
          <Link to="/" title="Home" reloadDocument>
            <Button>Back to home</Button>
          </Link>
        </div>
      </main>
    )
  } else if (error instanceof Error) {
    return (
      <main>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </main>
    )
  } else {
    return <h1>Unknown Error</h1>
  }
}
